import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import axios from 'axios';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

interface CreateRes {
  userEmail: string;
  userName: string;
  registerDate: string;
  isOldRegister: boolean;
  singleGoodsOrderCount: number;
  singleGoodsOrderAmount: number;
  singleGoodsVoiceCount: number;
  bundleVoiceCount: number;
  currentEnableVoiceCount: number;
  voiceCount: number;
  notDeleteVoiceCount: number;
  allVoiceCount: number;
  activeVoiceCount: number;
  deleteVoiceCount: number;
  isCancel: boolean;
}

interface SingleRes {
  userEmail: string;
  userName: string;
  transactionDate: string;
  orderId: number;
  orderUid: string;
  amount: number;
  count: number;
  transactionId: string;
  isDoubleEvent: boolean;
  isHalfEvent: boolean;
}

interface BundleRes {
  userEmail: string;
  userName: string;
  isAiaraEvent: boolean;
  transactionDate?: string;
  orderId?: number;
  transactionId?: number;
  amount?: number;
}

interface DataResponse<T> {
  success: string;
  message: string;
  data: T;
}

const humeloAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: { 'Service-Code': '001WEB001' },
});

const createDownload = async (
  data: CreateRes[],
  date: string,
  aivoiceDate: string,
) => {
  const wb = XLSX.utils.book_new();

  const headers = [
    [
      '',
      '계정(이메일)',
      '고객명',
      '회원가입일',
      `${dayjs(date).format('YY.M.D')} 회원가입 여부`,
      '마이 AI 보이스 단건구매 총 결제 횟수',
      '마이 AI 보이스 단건구매 총 결제금액',
      '단건구매로 취득한 마이 AI 보이스 총 이용권 개수',
      '번들 요금제로 취득한 마이 AI 보이스 이용권 개수',
      '사용하지 않고 남은 마이 AI 보이스 생성 개수',
      `${dayjs(aivoiceDate).format(
        'YY.M.D',
      )} 이후 생성한 마이 AI 보이스 개수 (삭제된 보이스 포함)`,
      `${dayjs(aivoiceDate).format(
        'YY.M.D',
      )} 이후 생성한 마이 AI 보이스 중 삭제되지 않은 마이 AI 보이스 개수`,
      ' 생성한 모든 마이 AI 보이스 개수',
      '활성화 상태 AI 보이스 개수',
      '삭제된 AI 보이스 개수',
      '환불대상 고객 여부',
    ],
  ];

  const res = data.map((item, i) => [
    i + 1,
    item.userEmail,
    item.userName,
    dayjs(repositoryUtils.parseDate(item.registerDate)).format('YYYY-MM-DD'),
    item.isOldRegister ? 'O' : '',
    item.singleGoodsOrderCount,
    item.singleGoodsOrderAmount,
    item.singleGoodsVoiceCount,
    item.bundleVoiceCount,
    item.currentEnableVoiceCount,
    item.voiceCount,
    item.notDeleteVoiceCount,
    item.allVoiceCount,
    item.activeVoiceCount,
    item.deleteVoiceCount,
    item.isCancel ? 'O' : '',
  ]);
  const ws = XLSX.utils.aoa_to_sheet([...headers, ...res]);
  XLSX.utils.book_append_sheet(wb, ws);

  XLSX.writeFile(
    wb,
    `잔여 이용권 및 마이 AI 보이스 생성 현황 ${dayjs().format(
      'YYYY-MM-DD',
    )}.xlsx`,
  );
};

const singleDownload = async (data: SingleRes[]) => {
  const wb = XLSX.utils.book_new();

  const headers = [
    [
      '',
      '계정(이메일)',
      '고객명',
      '마이 AI 보이스 단건 결제일',
      '주문번호',
      'TID',
      '마이 AI 보이스 단건 결제 금액',
      '취득한 마이 AI 보이스 이용권 개수',
      '반값 이벤트 적용 여부',
      '1+1 이벤트 적용 여부',
    ],
  ];

  const res = data.map((item, i) => [
    i + 1,
    item.userEmail,
    item.userName,
    dayjs(repositoryUtils.parseDate(item.transactionDate)).format('YYYY-MM-DD'),
    item.orderId,
    item.transactionId,
    item.amount,
    item.count,
    item.isHalfEvent ? 'O' : '',
    item.isDoubleEvent ? 'O' : '',
  ]);
  const ws = XLSX.utils.aoa_to_sheet([...headers, ...res]);
  XLSX.utils.book_append_sheet(wb, ws);

  XLSX.writeFile(wb, `단건 결제 현황 ${dayjs().format('YYYY-MM-DD')}.xlsx`);
};

const bundleDownload = async (data: BundleRes[]) => {
  const wb = XLSX.utils.book_new();

  const headers = [
    [
      '',
      '계정(이메일)',
      '고객명',
      '번들 요금제로 마이 AI 보이스 이용권 취득한 결제일',
      '주문번호',
      'TID',
      '결제금액',
    ],
  ];

  const res = data.map((item, i) => [
    i + 1,
    item.userEmail,
    item.userName,
    item.transactionDate
      ? dayjs(repositoryUtils.parseDate(item.transactionDate)).format(
          'YYYY-MM-DD',
        )
      : '',
    item.orderId,
    item.transactionId,
    item.amount,
  ]);
  const ws = XLSX.utils.aoa_to_sheet([...headers, ...res]);
  XLSX.utils.book_append_sheet(wb, ws);

  XLSX.writeFile(
    wb,
    `번들 요금제로 취득한 마이 AI 보이스 결제 이력 ${dayjs().format(
      'YYYY-MM-DD',
    )}.xlsx`,
  );
};

async function getCreateData(token: string, date: string, voiceDate: string) {
  try {
    const {
      data: { data },
    } = await humeloAxios.get<DataResponse<CreateRes[]>>(
      '/order/member-stats',
      {
        params: {
          date,
          voiceDate,
        },
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  } catch (e) {
    console.log(`Error: ${e}`);
  }
}

async function getSingleData(token: string) {
  try {
    const {
      data: { data },
    } = await humeloAxios.get<DataResponse<SingleRes[]>>(
      '/order/single-goods',
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  } catch (e) {
    console.log(`Error: ${e}`);
  }
}

async function getBundleData(token: string) {
  try {
    const {
      data: { data },
    } = await humeloAxios.get<DataResponse<BundleRes[]>>(
      '/order/plan/voice-bundle',
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  } catch (e) {
    console.log(`Error: ${e}`);
  }
}

export async function createDownloadExcel(date: string, voiceDate: string) {
  const { accessToken: token } = utils.token.get();

  if (token) {
    const data = await getCreateData(token, date, voiceDate);

    if (data) createDownload(data, date, voiceDate);
  }
}

export async function singleDownloadExcel() {
  const { accessToken: token } = utils.token.get();

  if (token) {
    const data = await getSingleData(token);

    if (data) singleDownload(data);
  }
}

export async function bundleDownloadExcel() {
  const { accessToken: token } = utils.token.get();

  if (token) {
    const data = await getBundleData(token);

    if (data) bundleDownload(data);
  }
}
